@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}

::-webkit-scrollbar-track {
  background: #f3f4f6;
}

::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border: 2px solid #f3f4f6;
  width: 8px;
}

::-webkit-scrollbar-button {
  display: none;
}
